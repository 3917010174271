import React, { Component } from "react"
import { navigate } from "@reach/router"
import { StaticQuery, graphql } from "gatsby"
import { withTranslation, I18nextContext } from 'gatsby-plugin-react-i18next';
import { VolcanoBookingWidget } from "@volcanoteide/vte-components"
import UIkit from 'uikit'
import {
	onBeginCheckoutStep1DL,
	onBeginCheckoutStep2DL,
	onBeginCheckoutStep3DL,
	onBeginCheckoutStep4DL
} from "./lib/dataLayer";

class Booking extends Component {

	static contextType = I18nextContext;

	componentDidMount() {
		if (typeof window !== `undefined`) {
			UIkit.container = '.uk-scope';
		}
	}

	render() {
		const destination = `/${this.context.language}/checkout/`;
		return	  <div className={`articulo reserva pb-5`}>
					<StaticQuery
						query={graphql`
							query BookingVolcanoTeideAPIConfig {
								site {
									siteMetadata {
										volcanoTeideAPIConfig {
											protocol
											host
											port
											strictSSL
											timeout
											site_key
										}
									}
								}
							}
						`}
						render={data => (
							<VolcanoBookingWidget
								apiConfig={data.site.siteMetadata.volcanoTeideAPIConfig}
								locale={this.context.language}
								experienceId={this.props.experience.experienceId}
								defaultSelection={ this.props.productId ? { productId: parseInt(this.props.productId)} : {}}
								checkIsActive={true}
								onConfirm={() => { navigate(destination) }}
								trackingCallbacks= {{
									onBeginCheckoutStep1DLCallback: () => {
										onBeginCheckoutStep1DL(this.props.experience);
									},
									onBeginCheckoutStep2DLCallback: (data) => {
										onBeginCheckoutStep2DL(this.props.experience, data);
									},
									onBeginCheckoutStep3DLCallback: (data) => {
										onBeginCheckoutStep3DL(this.props.experience, data);
									},
									onBeginCheckoutStep4DLCallback: (data) => {
										onBeginCheckoutStep4DL(this.props.experience, data);
									}}
								}
							/>
						)}
					/>
				</div>
	}
}

export default withTranslation('Booking')(Booking);
